import React from 'react';

import { BaseContentView, BaseProductView, BaseFiltersView } from '../core/views';

import { MaterialPreview } from '../core/components/materialPreview';

import { PackagingTypesWidget } from './components/packagingTypesWidget';
import { PackagingUvGraphicWidget } from './components/packagingUvGraphicWidget';
import { PackagingUvGraphicThemeWidget } from './components/packagingUvGraphicThemeWidget';
import { PackagingUvGraphicElementWidget } from './components/packagingUvGraphicElementWidget';
import { PackagingUvGraphicTextWidget } from './components/packagingUvGraphicTextWidget';
import { PackagingMaterialTypesWidget } from './components/packagingMaterialTypesWidget';
import { PackagingColorTypesWidget } from './components/packagingColorTypesWidget';
import { PackagingDecorationTypesWidget } from './components/packagingDecorationTypesWidget';
import { PackagingDebossingWidget } from './components/packagingDebossingWidget';
import { PackagingDebossingElementFormatWidget } from './components/packagingDebossingElementFormatWidget';
import { PackagingDebossingTextWidget } from './components/packagingDebossingTextsWidget';
import { PackagingDebossingElementColorsWidget } from './components/packagingDebossingElementColorsWidget';
import { PackagingDebossingElementSuggestedColorsWidget } from './components/packagingDebossingElementSuggestedColorsWidget';
import { FiltersTitle } from '../core/components/filtersTitle';

class ProductView extends BaseProductView {
  priceIsLoading() {
    let priceIsLoading;
    if (this.props.updatePrice) {
      priceIsLoading = false;
    } else {
      priceIsLoading =
        this.props.loadingCollection.eventBookPackagingPrice ||
        this.props.productConfigurationIsLoading;
    }
    return priceIsLoading;
  }

  renderPreview() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <MaterialPreview
            product={this.props.product}
            height={this.state.productPreviewHeight}
            width={this.state.productPreviewWidth}
          />
        </div>
      </div>
    );
  }
}

class FiltersView extends BaseFiltersView {
  getFilters() {
    return [
      <FiltersTitle key="FiltersTitle" title={this.i18n.gettext('Personalizza la confezione')} />,

      <PackagingTypesWidget
        key="PackagingTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingTypes}
        selected={this.props.configuration.packagingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingMaterialTypesWidget
        key="PackagingMaterialTypeWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingMaterialTypes}
        selected={this.props.configuration.packagingMaterialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingColorTypesWidget
        key="PackagingColorTypeWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingColorTypes}
        selected={this.props.configuration.packagingColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingDecorationTypesWidget
        key="PackagingDecorationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingDecorationTypes}
        selected={this.props.configuration.packagingDecorationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingDebossingWidget
        key="PackagingDebossingWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingDebossingTypes}
        selected={this.props.configuration.packagingDebossingType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingDebossingElementFormatWidget
        key="PackagingDebossingElementFormatWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingDebossingElementFormatTypes}
        selected={this.props.configuration.packagingDebossingElementType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingDebossingTextWidget
        key="PackagingDebossingTextWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingDebossingTextsCollection}
        value={this.props.configuration.packagingDebossingTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingDebossingElementSuggestedColorsWidget
        key="PackagingDebossingElementSuggestedColorsWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingDebossingElementColorTypes}
        selected={this.props.configuration.packagingDebossingElementColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingDebossingElementColorsWidget
        key="PackagingDebossingElementColorsWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingDebossingElementColorTypes}
        selected={this.props.configuration.packagingDebossingElementColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicWidget
        key="PackagingUvGraphicWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicTypes}
        selected={this.props.configuration.packagingUvGraphicType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicThemeWidget
        key="PackagingUvGraphicThemeWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicThemeTypes}
        selected={this.props.configuration.packagingUvGraphicThemeType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicElementWidget
        key="PackagingUvGraphicElementWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicElementTypes}
        selected={this.props.configuration.packagingUvGraphicElementType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicTextWidget
        key="PackagingUvGraphicTextWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvTexts}
        value={this.props.configuration.packagingUvTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />
    ];
  }
}

export class EventbookPackagingContentView extends BaseContentView {
  render() {
    return super.render(ProductView, FiltersView);
  }
}
