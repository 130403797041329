import React from 'react';

import { BaseBootstrapWithDescriptionWidget } from '../../core/components/baseBootstrapWithDescriptionWidget';

export class PackagingMaterialTypesWidget extends BaseBootstrapWithDescriptionWidget {
  get name() {
    return 'packagingMaterialType';
  }

  get label() {
    return this.i18n.gettext('Materiale:');
  }

  get descriptionSubTitle() {
    return this.i18n.gettext('Event Book Packaging Material Widget description');
  }

  get deprecatedDescription() {
    return this.i18n.gettext(
      'Le scatole degli Event Book Rivestiti sono realizzate con i seguenti materiali: Leonardo con un materiale che simula l’effetto della pelle dello squalo Michelangelo da un tessuto di viscosa Raffaello da un materiale soffice e vellutato Tiziano da un tessuto in lino colore naturale.'
    );
  }

  getItemsData(items) {
    let materialFabricCode = 'MATERIAL_FABRIC';
    let materialEcoleatherCode = 'MATERIAL_ECOLEATHER';

    return [
      {
        id: materialFabricCode,
        label: this.i18n.gettext('material fabric'),
        data: items.filter((item) => item.categoryCode === materialFabricCode)
      },
      {
        id: materialEcoleatherCode,
        label: this.i18n.gettext('material ecoleather'),
        data: items.filter((item) => item.categoryCode === materialEcoleatherCode)
      }
    ];
  }

  renderItems() {
    if (this.props.items) {
      let itemsData = this.getItemsData(this.props.items);
      return itemsData.map((itemColumn) => {
        return (
          <div key={`${itemColumn.id}-new`} className="col-xs-6 col-sm-6 col-md-6 no-padding-col">
            <div className="product__option__subtitle">{itemColumn.label}</div>
            {itemColumn.data.map((item) => {
              let activeClass = item.id === this.props.selected ? 'active' : '';
              let itemClass = `btn-ae text-center ${activeClass}`;
              return (
                <div
                  key={`${item.id}-new`}
                  className={itemClass}
                  onClick={this.handleClick.bind(this, item)}
                  data-action={`set_${this.props.items.name}_${item.id}`}>
                  {item.name}
                </div>
              );
            })}
          </div>
        );
      });
    }
    return null;
  }
}
