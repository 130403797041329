import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PackagingDebossingWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'packagingDebossingType';
  }

  get showWidget() {
    return false;
  }

  get label() {
    return this.i18n.gettext('packaging debossing');
  }
}
