import { EventBookPackagingConfiguration } from './models';

export async function getEventBookPackagingConfiguration(configurationId) {
  let configuration = null;

  if (configurationId) {
    configuration = new EventBookPackagingConfiguration({ id: configurationId });
    configuration = await configuration.fetch();
  }

  return configuration;
}
