import { BaseDebossingElementColorsWidget } from '../../core/components/baseDebossingElementColorsWidget';

export class PackagingDebossingElementColorsWidget extends BaseDebossingElementColorsWidget {
  get name() {
    return 'packagingDebossingElementColorType';
  }

  get label() {
    return this.i18n.gettext('packaging debossing available colors');
  }

  get configurationDebossingElementColorType() {
    return this.props.delegate.configuration.packagingDebossingElementColorType;
  }
}
