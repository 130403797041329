import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  EventBookPackagingProduct,
  EventBookPackagingPrice,
  EventBookPackagingType,
  EventBookPackagingColorMaterialType,
  EventBookPackagingUvGraphicType,
  EventBookPackagingUvGraphicThemeType,
  EventBookPackagingUvGraphicElementType,
  EventBookPackagingUvGraphicText,
  EventBookPackagingMaterialType,
  EventBookPackagingColorType,
  EventBookPackagingDecorationType,
  EventBookPackagingDebossingType,
  EventBookPackagingDebossingElementFormatType,
  EventBookPackagingDebossingElementColorType,
  EventBookPackagingDebossingTextType
} from './models';

export class EventBookPackagingProductCollection extends LoadableCollection {
  get name() {
    return 'eventBookPackagingProducts';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_PRODUCTS_URL;
  }

  get model() {
    return EventBookPackagingProduct;
  }
}

export class EventBookPackagingPriceCollection extends LoadableCollection {
  get name() {
    return 'eventBookPackagingPrice';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_PRICES_URL;
  }

  get model() {
    return EventBookPackagingPrice;
  }
}

export class EventBookPackagingTypeCollection extends LoadableCollection {
  get name() {
    return 'packagingTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_TYPES_URL;
  }

  get model() {
    return EventBookPackagingType;
  }
}

export class EventBookPackagingDecorationTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingDecorationTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_DECORATION_URL;
  }

  get model() {
    return EventBookPackagingDecorationType;
  }

  get loadParams() {
    return ['packagingType', 'formatType'];
  }
}

export class EventBookPackagingDebossingTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingDebossingTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_DEBOSSING_URL;
  }

  get model() {
    return EventBookPackagingDebossingType;
  }

  get loadParams() {
    return ['packagingType', 'packagingDecorationType'];
  }
}

export class EventBookPackagingDebossingElementFormatTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingDebossingElementFormatTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_DEBOSSING_ELEMENT_URL;
  }

  get model() {
    return EventBookPackagingDebossingElementFormatType;
  }

  get loadParams() {
    return [
      'packagingType',
      'formatType',
      'packagingDebossingType',
      'packagingMaterialType',
      'packagingColorType'
    ];
  }
}

export class EventBookPackagingDebossingElementColorTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingDebossingElementColorTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_DEBOSSING_ELEMENT_COLOR_URL;
  }

  get model() {
    return EventBookPackagingDebossingElementColorType;
  }

  get loadParams() {
    return [
      'packagingType',
      'formatType',
      'packagingDebossingType',
      'packagingMaterialType',
      'packagingColorType',
      'packagingDebossingElementFormatType'
    ];
  }
}

export class EventBookPackagingDebossingTextsCollection extends LoadableCollection {
  get name() {
    return 'packagingDebossingTextsCollection';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_DEBOSSING_TEXTS_URL;
  }

  get model() {
    return EventBookPackagingDebossingTextType;
  }

  get loadParams() {
    return [
      'packagingType',
      'formatType',
      'packagingDebossingType',
      'packagingDebossingElementFormatType'
    ];
  }
}

export class EventBookPackagingColorMaterialTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingColorMaterialTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_COLORS_MATERIALS_URL;
  }

  get model() {
    return EventBookPackagingColorMaterialType;
  }

  get loadParams() {
    return ['packagingType', 'formatType', 'pageSize'];
  }
}

export class EventBookPackagingMaterialTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingMaterialTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_MATERIALS_URL;
  }

  get model() {
    return EventBookPackagingMaterialType;
  }

  get loadParams() {
    return ['packagingType', 'formatType', 'pageSize'];
  }
}

export class EventBookPackagingColorTypesCollection extends LoadableCollection {
  get name() {
    return 'packagingColorTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_COLORS_URL;
  }

  get model() {
    return EventBookPackagingColorType;
  }

  get loadParams() {
    return ['packagingType', 'formatType', 'packagingMaterialType', 'pageSize'];
  }
}

export class EventBookPackagingUvGraphicCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_UV_GRAPHIC_URL;
  }

  get model() {
    return EventBookPackagingUvGraphicType;
  }

  get loadParams() {
    return ['packagingType', 'packagingDecorationType'];
  }
}

export class EventBookPackagingUvGraphicThemeCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicThemeTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_UV_GRAPHIC_THEME_URL;
  }

  get model() {
    return EventBookPackagingUvGraphicThemeType;
  }

  get loadParams() {
    return ['packagingType', 'packagingUvGraphicType'];
  }
}

export class EventBookPackagingUvGraphicElementCollection extends LoadableCollection {
  get name() {
    return 'packagingUvGraphicElementTypes';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_UV_GRAPHIC_ELEMENT_URL;
  }

  get model() {
    return EventBookPackagingUvGraphicElementType;
  }

  get loadParams() {
    return [
      'bindingType',
      'formatType',
      'packagingType',
      'packagingColorMaterialType',
      'packagingMaterialType',
      'packagingColorType',
      'packagingUvGraphicType',
      'packagingUvGraphicThemeType'
    ];
  }
}

export class EventBookPackagingUvGraphicTextCollection extends LoadableCollection {
  get bypassDefault() {
    return true;
  }

  get name() {
    return 'packagingUvTexts';
  }

  get url() {
    return conf.settings.EVENT_BOOK_PACKAGING_UV_GRAPHIC_TEXT_URL;
  }

  get model() {
    return EventBookPackagingUvGraphicText;
  }

  get loadParams() {
    return [
      'packagingType',
      'formatType',
      'packagingUvGraphicType',
      'packagingUvGraphicThemeType',
      'packagingUvGraphicElementType'
    ];
  }
}
