import { BaseDebossingElementSuggestedColorsWidget } from '../../core/components/baseDebossingElementSuggestedColorsWidget';

export class PackagingDebossingElementSuggestedColorsWidget extends BaseDebossingElementSuggestedColorsWidget {
  get name() {
    return 'packagingDebossingElementColorType';
  }

  get label() {
    return this.i18n.gettext('packaging debossing suggested colors');
  }

  get configurationDebossingElementColorType() {
    return this.props.delegate.configuration.packagingDebossingElementColorType;
  }
}
