import { BaseSelectionWidgetFourColumns } from '../../core/components/baseSelectionWidgetFourColumns';

export class PackagingUvGraphicThemeWidget extends BaseSelectionWidgetFourColumns {
  get name() {
    return 'packagingUvGraphicThemeType';
  }

  get label() {
    return this.i18n.gettext('tipo di evento');
  }

  get calculatePrice() {
    return false;
  }
}
