import { GraphicCollapsableWidget } from '../../core/components/graphicCollapsableWidget';

export class PackagingUvGraphicElementWidget extends GraphicCollapsableWidget {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items && this.props.selected !== nextProps.selected) {
      this.setState({ isChosen: false }); //need to reopen the item selector
      this.collectErrors(nextProps);
    }

    //ned to set the new svg color
    if (nextProps.items && this.props.items !== nextProps.items) {
      if (nextProps.items.length === 0) {
        this.props.delegate.clearPreviewSvg();
      }
      nextProps.items.each(
        function (item) {
          if (item.isDefault || (this.props.selected && item.id === this.props.selected)) {
            this.props.delegate.chosePreviewSvgColor(item.uvGraphicColor);
            this.props.delegate.chosePreviewSvg(item.svgUrl);
            this.props.delegate.setPreviewSvg(item.svgUrl);
          }
        }.bind(this)
      );
    }
  }

  get name() {
    return 'packagingUvGraphicElementType';
  }

  get label() {
    return this.i18n.gettext('elemento grafico');
  }

  get calculatePrice() {
    return false;
  }

  handleMouseOver(item) {
    this.props.delegate.setPreviewSvgColor(item.uvGraphicColor);
    let rowlimits = {
      1: item.svgText1MaxLength,
      2: item.svgText2MaxLength
    };
    this.props.delegate.setPreviewSvg(item.svgUrl, rowlimits);
  }

  handleMouseOut() {
    this.props.delegate.setPreviewSvg();
  }

  handleClick(item) {
    super.handleClick(item);
    if (!this.loading) {
      this.props.delegate.setPreviewSvgColor(item.uvGraphicColor);
      this.props.delegate.chosePreviewSvg(item.svgUrl);
    }
  }
}
