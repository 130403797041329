import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class EventBookPackagingProduct extends BaseModel {
  get id() {
    return this.get('code');
  }

  get frontAssetType() {
    return this.get('frontAssetType');
  }

  get frontAssetUrl() {
    return this.get('frontAssetUrl');
  }

  get uvGraphicColor() {
    return this.get('uvGraphicColor');
  }

  get svgAssetType() {
    return this.get('svgAssetType');
  }

  get svgAssetUrl() {
    return this.get('svgAssetUrl');
  }

  get debossingColor() {
    return this.get('debossingElementColor');
  }
}

export class EventBookPackagingConfiguration extends BaseModel {
  get urlRoot() {
    return conf.settings.EVENT_BOOK_PACKAGING_CONFIGURATIONS_URL;
  }

  getAbsoluteUrl() {
    return this.urlRoot + this.id + '/';
  }

  get id() {
    return this.get('id');
  }

  get configurationGuid() {
    return this.get('code');
  }

  get productType() {
    return 'EventBookPackaging';
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get previewPackagingCode() {
    return this.get('previewPackagingCode');
  }

  set previewPackagingCode(value) {
    this.set('previewPackagingCode', value);
  }

  get packagingUvGraphicType() {
    return this.get('packagingUvGraphicType');
  }

  set packagingUvGraphicType(value) {
    this.set('packagingUvGraphicType', value);
  }

  get packagingUvGraphicThemeType() {
    return this.get('packagingUvGraphicThemeType');
  }

  set packagingUvGraphicThemeType(value) {
    this.set('packagingUvGraphicThemeType', value);
  }

  get packagingUvGraphicElementType() {
    return this.get('packagingUvGraphicElementType');
  }

  set packagingUvGraphicElementType(value) {
    this.set('packagingUvGraphicElementType', value);
  }

  get formatType() {
    return this.get('formatType');
  }

  set formatType(value) {
    this.set('formatType', value);
  }

  get packagingColorMaterialType() {
    return this.get('packagingColorMaterialType');
  }

  set packagingColorMaterialType(value) {
    this.set('packagingColorMaterialType', value);
  }

  get packagingMaterialType() {
    return this.get('packagingMaterialType');
  }

  set packagingMaterialType(value) {
    this.set('packagingMaterialType', value);
  }

  get packagingColorType() {
    return this.get('packagingColorType');
  }

  set packagingColorType(value) {
    this.set('packagingColorType', value);
  }

  get packagingType() {
    return this.get('packagingType');
  }

  set packagingType(value) {
    this.set('packagingType', value);
  }

  get packagingDecorationType() {
    return this.get('packagingDecorationType');
  }

  set packagingDecorationType(value) {
    this.set('packagingDecorationType', value);
  }

  get packagingDebossingType() {
    return this.get('packagingDebossingType');
  }

  set packagingDebossingType(value) {
    this.set('packagingDebossingType', value);
  }

  get packagingDebossingElementType() {
    return this.get('packagingDebossingElementType');
  }

  set packagingDebossingElementType(value) {
    this.set('packagingDebossingElementType', value);
  }

  get packagingDebossingElementFormatType() {
    return this.get('packagingDebossingElementFormatType');
  }

  set packagingDebossingElementFormatType(value) {
    this.set('packagingDebossingElementFormatType', value);
  }

  get packagingDebossingTexts() {
    return this.get('packagingDebossingTexts');
  }

  set packagingDebossingTexts(value) {
    this.set('packagingDebossingTexts', value);
  }

  get packagingDebossingElementColorType() {
    return this.get('packagingDebossingElementColorType');
  }

  set packagingDebossingElementColorType(value) {
    this.set('packagingDebossingElementColorType', value);
  }

  get packagingUvTexts() {
    return this.get('packagingUvTexts');
  }

  set packagingUvTexts(value) {
    this.set('packagingUvTexts', value);
  }

  get serviceConfigurationTypeCode() {
    return this.get('serviceConfigurationTypeCode');
  }

  set serviceConfigurationTypeCode(value) {
    this.set('serviceConfigurationTypeCode', value);
  }

  get serviceConfigurationTypeName() {
    return this.get('serviceConfigurationTypeName');
  }
}

export class EventBookPackagingPrice extends BaseModel {
  get price() {
    return this.get('price');
  }

  get formattedPrice() {
    return this.get('formattedPrice');
  }

  get currency() {
    return this.get('currency');
  }
}

export class EventBookPackagingType extends BaseModel {
  static get modelName() {
    return 'packagingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookPackagingDecorationType extends BaseModel {
  static get modelName() {
    return 'packagingDecorationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get description() {
    return this.get('description');
  }
}

export class EventBookPackagingDebossingType extends BaseModel {
  static get modelName() {
    return 'packagingDebossingType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookPackagingDebossingElementFormatType extends BaseModel {
  static get modelName() {
    return 'packagingDebossingElementFormatType';
  }

  get id() {
    return this.get('elementFormatTypeCode');
  }

  get name() {
    return this.get('name');
  }

  get code() {
    return this.get('code');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get debossingColor() {
    let color = this.get('debossingColor');
    return `#${color}`;
  }

  get svgText1MaxLength() {
    return this.get('svgText1MaxLength');
  }

  get svgText2MaxLength() {
    return this.get('svgText2MaxLength');
  }

  get svgUrl() {
    return this.get('svgUrl');
  }
}

export class EventBookPackagingDebossingElementColorType extends BaseModel {
  static get modelName() {
    return 'packagingDebossingElementColorType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get colorCode() {
    let color = this.get('colorCode');
    return `#${color}`;
  }

  get isMetallicColor() {
    return this.get('isMetallicColor');
  }

  get isSuggested() {
    return this.get('isSuggested');
  }

  get orderIndex() {
    return this.get('orderIndex');
  }
}

export class EventBookPackagingDebossingTextType extends BaseModel {
  static get modelName() {
    return 'packagingDebossingTexts';
  }

  get id() {
    return this.get('rowCode');
  }

  get rowCode() {
    return this.get('rowCode');
  }

  get contentType() {
    return this.get('contentType');
  }

  get fieldType() {
    return this.get('fieldType');
  }

  get isRequired() {
    return this.get('isRequired');
  }

  get maxTextLength() {
    return this.get('maxTextLength');
  }

  get minTextLength() {
    return this.get('minTextLength');
  }

  get rowNumber() {
    return this.get('rowNumber');
  }

  get name() {
    return this.get('name');
  }
}

export class EventBookPackagingColorMaterialType extends BaseModel {
  static get modelName() {
    return 'packagingColorMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get description() {
    return this.get('description');
  }

  get packagingImageUrl() {
    return this.get('packagingImageUrl');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get uvGraphicColor() {
    let color = this.get('uvGraphicColor');
    return `#${color}`;
  }
}

export class EventBookPackagingMaterialType extends BaseModel {
  static get modelName() {
    return 'packagingMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get description() {
    return this.get('description');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get categoryCode() {
    return this.get('categoryCode');
  }
}

export class EventBookPackagingColorType extends BaseModel {
  static get modelName() {
    return 'packagingColorType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get description() {
    return this.get('description');
  }

  get packagingImageUrl() {
    return this.get('packagingImageUrl');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get uvGraphicColor() {
    let color = this.get('uvGraphicColor');
    return `#${color}`;
  }
}

export class EventBookPackagingUvGraphicType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookPackagingUvGraphicThemeType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicThemeType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class EventBookPackagingUvGraphicElementType extends BaseModel {
  static get modelName() {
    return 'packagingUvGraphicElementType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get svgUrl() {
    return this.get('svgUrl');
  }

  get uvGraphicColor() {
    let color = this.get('uvGraphicColor');
    return `#${color}`;
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get svgText1MaxLength() {
    return this.get('svgText1MaxLength');
  }

  get svgText2MaxLength() {
    return this.get('svgText2MaxLength');
  }
}

export class EventBookPackagingUvGraphicText extends BaseModel {
  static get modelName() {
    return 'packagingUvTexts';
  }

  get id() {
    return this.get('codeRow1');
  }

  get codeRow1() {
    return this.get('codeRow1');
  }

  get nameRow1() {
    return this.get('nameRow1');
  }

  get charLimitRow1() {
    return this.get('charLimitRow1');
  }

  get isRequiredRow1() {
    return this.get('isRequiredRow1');
  }

  get codeRow2() {
    return this.get('codeRow2');
  }

  get nameRow2() {
    return this.get('nameRow2');
  }

  get charLimitRow2() {
    return this.get('charLimitRow2');
  }

  get isRequiredRow2() {
    return this.get('isRequiredRow2');
  }
}
