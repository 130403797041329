import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { BaseConfiguratorController } from '../core/controllers';
import {
  GuiErrors,
  updateConfigurationsShopCode,
  createPreOrder,
  navigateTo,
  mergeQueryParams
} from '../core/utils/index';
import Logger from '../core/logger';

import { eventBookFunnelSteps } from '../core/utils/breadcrumb';
import { EventbookPackagingContentView } from './views';
import {
  EventBookPackagingTypeCollection,
  EventBookPackagingUvGraphicCollection,
  EventBookPackagingUvGraphicThemeCollection,
  EventBookPackagingUvGraphicElementCollection,
  EventBookPackagingUvGraphicTextCollection,
  EventBookPackagingProductCollection,
  EventBookPackagingPriceCollection,
  EventBookPackagingMaterialTypesCollection,
  EventBookPackagingColorTypesCollection,
  EventBookPackagingDecorationTypesCollection,
  EventBookPackagingDebossingTypesCollection,
  EventBookPackagingDebossingElementFormatTypesCollection,
  EventBookPackagingDebossingElementColorTypesCollection,
  EventBookPackagingDebossingTextsCollection
} from './managers';
import { EventBookPackagingConfiguration } from './models';

import { getEventBookPackagingConfiguration } from './utils';
import { getEventBookProductConfigurationPriceParams } from '../eventbook/utils';
import { InvalidPackagingConfigurationError, PackagingConfigurationError } from './errors';
import { EventbookProjectStorage } from '../core/storage/index';

import { getEventBookConfiguration } from '../eventbook/utils';
import { ProfessionalPreOrdersCollection } from '../core/managers';

import { EventBookPriceCollection } from '../eventbook/managers';
import EventTracker, { productCategoryInterface, productInterface } from '../core/eventTracker';

/**
 * Main controller for eventbook packaging
 * */
export class EventBookPackagingController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get funnelStep() {
    return 2;
  }

  get funnelSteps() {
    return eventBookFunnelSteps(this.request);
  }

  getCallToAction() {
    return this.i18n.gettext('Procedi');
  }

  getTitle() {
    return this.i18n.gettext('Scatola');
  }

  get context() {
    return Object.assign(super.context, {
      product: this.product,
      configuration: this.configuration,
      filters: this.filters,
      filtersViewValidity: this.filtersViewValidity,
      priceValidity: this.priceValidity,
      errorsVisibility: this.errorsVisibility,
      initViewRendering: this.initViewRendering,
      loadingCollection: this.loadingCollection,
      loadingPage: this.loadingPage,
      productConfigurationIsLoading: this.productConfigurationIsLoading,
      filtersViewIsLoading: this.filtersViewIsLoading,
      updatePrice: this.updatePrice
    });
  }

  get view() {
    return EventbookPackagingContentView;
  }

  async getEventBookPreOrder() {
    return await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      this.request.query.configurationId,
      conf.settings.EVENT_BOOK_PRODUCT_TYPE
    );
  }

  async getEventBookPackagingPreOrder() {
    return await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      this.request.query.packagingConfigurationId,
      conf.settings.EVENT_BOOK_PACKAGING_PRODUCT_TYPE
    );
  }

  async initPreOrders() {
    if (!(this.request.query.configurationId && this.request.query.packagingConfigurationId)) {
      return [null, null];
    }

    let preOrders = await Promise.all([
      this.getEventBookPreOrder(),
      this.getEventBookPackagingPreOrder()
    ]);

    if (preOrders.length > 0 && preOrders[0].first() && preOrders[1].first()) {
      return [preOrders[0].first().id, preOrders[1].first().id, preOrders[0].first().projectId];
    }
    return [null, null, null];
  }

  async initContentProps() {
    super.initContentProps();

    this.product = {
      productItem: {},
      previewUrl: null,
      previewSvg: null,
      previewSvgUrl: null,
      previewDebossingSvg: null,
      previewDebossingSvgUrl: null,
      previewTexLimit1: null,
      previewTexLimit2: null,
      productConfiguration: new EventBookPackagingConfiguration(),
      svgColor: null,
      debossingSvgColor: null,
      chosenSvgColor: null,
      chosenDebossingSvgColor: null,
      price: {},
      showWidgetPrice: this.customerUserIsAuthorized,
      productPrice: {}
    };

    this.showNotify = false;
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      await this.loadInitialConfiguration();
      await this.loadProductConfigurationPrice();
      this.reloadView();

      EventTracker.log(
        this.customerUser,
        'configurator_packaging_view',
        productInterface(this.product.productConfiguration)
      );
    }
  }

  async loadInitialConfiguration() {
    let packagingConfigurationId = this.request.query.packagingConfigurationId;

    try {
      let packagingConfiguration = new EventBookPackagingConfiguration({
        id: packagingConfigurationId
      });
      packagingConfiguration = await packagingConfiguration.fetch();

      this.product.productConfiguration = packagingConfiguration;
      this.configuration = packagingConfiguration.toJSON(); // la configurazione è un JSON (NON un backbone model)
    } catch (error) {
      if (error.code === 404) {
        GuiErrors.modalInfo(
          this.i18n.gettext(
            'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
          )
        );
        // this.response.navigate('eventbook:new', {});
        navigateTo(this.request, this.response, 'eventbook:new', {}, false, null);
      } else {
        if (error instanceof InvalidPackagingConfigurationError) {
          let timestamp = new Date().getTime();
          GuiErrors.modalError(
            this.i18n.gettext(
              'Non è stato possibile caricare la configurazione della scatola, si prega di ripetere la procedura.'
            ),
            'Error detail: loadPackagingConfiguration - code: ' + timestamp
          );
          // this.response.navigate('eventbook:main', {});
          navigateTo(this.request, this.response, 'eventbook:main', {}, false, this.request.query);
        } else {
          new PackagingConfigurationError('EventbookPackagingController.init', { //eslint-disable-line
            packagingConfiguration: this.configuration,
            error: error
          });
        }
      }
    } finally {
      this.stopInitialRendering();
    }
  }

  async loadProductConfigurationPrice() {
    let productConfiguration;
    try {
      productConfiguration = await getEventBookConfiguration(this.request.query.configurationId);
    } catch (error) {
      if (error.code === 404) {
        GuiErrors.modalInfo(
          this.i18n.gettext(
            'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
          )
        );
        navigateTo(this.request, this.response, 'eventbook:new', {}, false, null);
      } else {
        new PackagingConfigurationError('EventbookPackagingController.loadProductConfigurationPrice - failed to fetch eventBook productConfiguration', { //eslint-disable-line
            packagingConfiguration: this.configuration,
            productConfigurationId: this.request.query.configurationId,
            error: error
          }
        );
      }
    } finally {
      this.stopInitialRendering();
    }

    if (productConfiguration) {
      let priceParams = await getEventBookProductConfigurationPriceParams(
        null,
        productConfiguration
      );
      priceParams = Object.assign(priceParams, {
        language: this.getLanguage(),
        shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null
      });

      try {
        let price = await this.getPrice(new EventBookPriceCollection(), priceParams);
        this.productPrice = price;
        this.setProductConfigurationPrice(price);
        this.setProductConfigurationName(this.i18n.gettext('Event Book'));
      } catch (error) {
        Logger.error('EventBook - Loading product price error', {
          error: error,
          priceParams: priceParams,
          productConfiguration: productConfiguration
        });
      }
    }
  }

  getPreviewTexts() {
    // return this.configuration.packagingUvTexts;
    if (
      this.configuration.packagingDecorationType ===
      conf.settings.EVENT_BOOK_PACKAGING_DECORATION_DEBOSSING_TYPE
    ) {
      return this.configuration.packagingDebossingTexts;
    }
    return this.configuration.packagingUvTexts;
  }

  // OVERRIDE DEFAULT method
  getParamsForCollection(collection) {
    let params = {};
    for (let value of collection.loadParams) {
      /*HACK: the page size could be implicit*/
      if (value === 'pageSize' && !this.configuration[value]) {
        params[value] = 50;
      } else if (value === 'configurationId') {
        params[value] = this.product.productConfiguration.id;
      } else {
        params[value] = this.configuration[value];
      }
    }

    return params;
  }

  // OVERRIDE DEFAULT method
  async loadCollection(collection) {
    this.startCollectionLoading(collection.name);
    let params = this.getParamsForCollection(collection);

    delete params.packagingColorMaterialType;

    return collection
      .loadByParams(params)
      .then((items) => {
        this.filters[collection.name] = items;
        this.setDefaultValueByItems(items);
        this.stopCollectionLoading(collection.name);
      })
      .catch((err) => {
        this.addFilterToErrorCollection(collection.name);
        Logger.error('Loading collection error', {
          error: err,
          params: params,
          module: collection.model.modelName
        });
      });
  }

  async reloadView(getPrice = true) {
    this.filtersViewIsLoading = true;
    this.productConfigurationIsLoading = true;
    if (getPrice) {
      this.updatePrice = false;
    }
    this.render(this.context);
    try {
      this.resetFilterErrorsCollection();

      await this.loadCollection(new EventBookPackagingTypeCollection());

      await Promise.all([
        this.loadCollection(new EventBookPackagingMaterialTypesCollection()),
        this.loadCollection(new EventBookPackagingDecorationTypesCollection())
      ]);

      /*
       * Inizializzare la fase di rendering prima dei widget UV, altrimenti non vengono caricati gli SVG
       * */
      await Promise.all([
        this.loadCollection(new EventBookPackagingColorTypesCollection()),
        this.loadCollection(new EventBookPackagingUvGraphicCollection()),
        this.loadCollection(new EventBookPackagingDebossingTypesCollection())
      ]);

      await this.loadCollection(new EventBookPackagingUvGraphicThemeCollection());

      await Promise.all([
        this.loadCollection(new EventBookPackagingUvGraphicElementCollection()),
        this.loadCollection(new EventBookPackagingDebossingElementFormatTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new EventBookPackagingUvGraphicTextCollection()),
        this.loadCollection(new EventBookPackagingDebossingTextsCollection()),
        this.loadCollection(new EventBookPackagingDebossingElementColorTypesCollection())
      ]);

      /*
       * DEVO RICARICARE IL PRODOTTO PER AVERE il campo previewBookCode sempre aggiornato
       * */
      await this.loadProduct();
    } catch (err) {
      Logger.error('EventbookPackagingController.reloadView-loadFilters', {
        error: err,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    } finally {
      this.filtersViewIsLoading = false;
      this.render(this.context);
    }

    if (this.filters === []) {
      let timestamp = new Date().getTime();
      Logger.error('EventbookPackagingController.reloadView-emptyfilters', {
        error: 'Empty filters',
        errorCode: timestamp,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    } else {
      try {
        this.loadProduct();
        if (getPrice) {
          this.loadPrice();
        }
      } catch (err) {
        Logger.error('EventbookPackagingController.reloadView-LoadProduct', {
          error: err,
          configuration: this.configuration,
          productConfiguration: this.product.productConfiguration,
          filters: this.filters
        });
      } finally {
        this.productConfigurationIsLoading = false;
        this.updatePrice = true;
        this.render(this.context);
      }
    }
  }

  async loadProduct() {
    let productParams = {
      formatType: this.configuration.formatType,
      packagingMaterialType:
        this.filters.packagingMaterialTypes && this.filters.packagingMaterialTypes.length > 0
          ? this.configuration.packagingMaterialType
          : null,
      packagingColorType:
        this.filters.packagingColorTypes && this.filters.packagingColorTypes.length > 0
          ? this.configuration.packagingColorType
          : null,
      packagingType:
        this.filters.packagingTypes && this.filters.packagingTypes.length > 0
          ? this.configuration.packagingType
          : null,
      packagingUvGraphicType:
        this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0
          ? this.configuration.packagingUvGraphicType
          : null,
      packagingUvGraphicThemeType:
        this.filters.packagingUvGraphicThemeTypes &&
        this.filters.packagingUvGraphicThemeTypes.length > 0
          ? this.configuration.packagingUvGraphicThemeType
          : null,
      packagingUvGraphicElementType:
        this.filters.packagingUvGraphicElementTypes &&
        this.filters.packagingUvGraphicElementTypes.length > 0
          ? this.configuration.packagingUvGraphicElementType
          : null,
      packagingDebossingType:
        this.filters.packagingDebossingTypes && this.filters.packagingDebossingTypes.length > 0
          ? this.configuration.packagingDebossingType
          : null,
      packagingDebossingElementFormatType:
        this.filters.packagingDebossingElementFormatTypes &&
        this.filters.packagingDebossingElementFormatTypes.length > 0
          ? this.configuration.packagingDebossingElementFormatType
          : null,
      packagingDebossingElementColorType:
        this.filters.packagingDebossingElementColorTypes &&
        this.filters.packagingDebossingElementColorTypes.length > 0
          ? this.configuration.packagingDebossingElementColorType
          : null
    };

    let product = await this.getProduct(new EventBookPackagingProductCollection(), productParams);
    if (product) {
      this.setEventBookPackagingProduct(product);
    } else {
      Logger.error('EventBook Packaging Product must be defined', {
        productParams: productParams,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    }
  }

  async loadPrice() {
    let priceParams = {
      shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null,
      formatType: this.configuration.formatType,
      packagingType:
        this.filters.packagingTypes && this.filters.packagingTypes.length > 0
          ? this.configuration.packagingType
          : null,
      packagingUvGraphicType:
        this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0
          ? this.configuration.packagingUvGraphicType
          : null,
      packagingDebossingType:
        this.filters.packagingDebossingTypes && this.filters.packagingDebossingTypes.length > 0
          ? this.configuration.packagingDebossingType
          : null,
      language: this.getLanguage()
    };
    let price = await this.getPrice(new EventBookPackagingPriceCollection(), priceParams);
    if (price) {
      this.setConfigurationPrice(price);
    }
  }

  async setEventBookPackagingProduct(productItem) {
    super.setConfigurationProduct(productItem);
    if (
      this.configuration.packagingDecorationType ===
      conf.settings.EVENT_BOOK_PACKAGING_DECORATION_DEBOSSING_TYPE
    ) {
      await this.setDebossingPreviewSvg();
    } else {
      await this.setPreviewSvg();
    }
    this.render(this.context);
  }

  async saveProductConfiguration() {
    let eventbookPackagingConfiguration = null;
    try {
      let currentConfiguration = this.configuration;

      currentConfiguration.shopCode = this.customerUserIsAuthorized
        ? this.customerUser.shopCode
        : null;
      currentConfiguration.previewPackagingCode = this.product.productItem.id;

      // delete cover uv texts if graphic UV is not selected
      if (!this.filters.packagingUvTexts || (this.filters.packagingUvTexts && this.filters.packagingUvTexts.length === 0)) { //eslint-disable-line
        currentConfiguration.packagingUvTexts = {};
      }

      // delete debossing uv texts if debossing is not selected
      if (!this.filters.packagingDebossingTextsCollection || (this.filters.packagingDebossingTextsCollection && this.filters.packagingDebossingTextsCollection.length === 0)) { //eslint-disable-line
        currentConfiguration.packagingDebossingTexts = {};
      }

      eventbookPackagingConfiguration = await this.product.productConfiguration.save(
        currentConfiguration
      );
    } catch (error) {
      let timestamp = new Date().getTime();
      Logger.error('EventbookPackagingController.saveProductConfiguration', {
        error: error,
        errorCode: timestamp,
        product: this.product
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la configurazione della scatola, si prega di ripetere la procedura.'
        ),
        'Error detail: saveProductConfiguration - code: ' + timestamp
      );
      // this.response.navigate('eventbook-packaging:main', this.request.query);
      navigateTo(
        this.request,
        this.response,
        'eventbook-packaging:main',
        {},
        false,
        this.request.query
      );
    }

    return eventbookPackagingConfiguration;
  }

  async savePreorder() {
    /** check if preorder already exist **/
    [this.preOrderId, this.packagingPreOrderId, this.projectId] = await this.initPreOrders();
    if (this.preOrderId && this.packagingPreOrderId && this.projectId) {
      return [true, undefined];
    }

    /** get stored configurations **/
    let [eventBookConfiguration, packagingConfiguration] = await Promise.all([
      getEventBookConfiguration(this.request.query.configurationId),
      getEventBookPackagingConfiguration(this.request.query.packagingConfigurationId)
    ]);

    /** check configurations **/
    if (!eventBookConfiguration || !packagingConfiguration) {
      let timestamp = new Date().getTime();
      GuiErrors.modalError(
        this.i18n.gettext(
          'Qualcosa è andato storto. Ti preghiamo di riprovare riconfigurando un nuovo prodotto.'
        ),
        'Error detail: preorder creation non configuration found - code: ' + timestamp
      );
      navigateTo(this.request, this.response, 'eventbook:main', {}, false, this.request.query);
      return [false, new Error('Configurations not found')];
    }

    let timestamp = new Date().getTime();

    /** check shop codes **/
    if (!eventBookConfiguration.shopCode || !packagingConfiguration.shopCode) {
      try {
        [eventBookConfiguration, packagingConfiguration] = await updateConfigurationsShopCode(
          eventBookConfiguration,
          packagingConfiguration,
          this.customerUser
        );
      } catch (err) {
        Logger.error(
          'EventBook savePreorder.updateConfigurationsShopCode - Unable to load configurations',
          {
            error: err,
            errorCode: timestamp,
            user: this.user,
            customerUser: this.customerUser,
            eventBookConfiguration: eventBookConfiguration,
            packagingConfiguration: packagingConfiguration
          }
        );
        GuiErrors.modalError(
          this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
          'Error detail: loadConfigurations - code: ' + timestamp
        );
        return [false, err];
      }
    }

    /** Create Pre-Orders **/
    try {
      let eventBookPreorder = await createPreOrder(
        this.customerUser,
        'EventBook',
        eventBookConfiguration,
        null,
        eventBookConfiguration.configurationGuid
      );
      this.preOrderId = eventBookPreorder.id;
      await EventbookProjectStorage.setPk(eventBookPreorder.projectId);
      this.projectId = eventBookPreorder.projectId;
      let eventBookPackagingPreorder = await createPreOrder(
        this.customerUser,
        'EventBook',
        packagingConfiguration,
        eventBookPreorder.projectId,
        packagingConfiguration.configurationGuid
      );
      this.packagingPreOrderId = eventBookPackagingPreorder.id;
      this.showNotify = true;

      EventTracker.log(this.customerUser, 'bucket_add_success', {
        product_category: productCategoryInterface(this.product.productConfiguration.productType),
        project_id: this.projectId
      });

      return [true, undefined];
    } catch (err) {
      Logger.error('EventBook savePreorder.createPreOrder', {
        error: err,
        errorCode: timestamp,
        user: this.user,
        customerUser: this.customerUser,
        eventBookConfiguration: eventBookConfiguration,
        packagingConfiguration: packagingConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
        'Error detail: createPreOrder - code: ' + timestamp,
        this.i18n.gettext('Chiudi'),
        null,
        null
      );
      return [false, err];
    }
  }

  async goToNextStep() {
    this.errorsVisibility = true;
    this.startLoadingPage();
    if (await this.checkWidgetErrors()) {
      if (!this.isUserLoggedIn()) {
        this.redirectToLoginPage();
        return null;
      }

      let productConfiguration = await this.saveProductConfiguration();

      if (this.customerUser && !this.customerUser.shopCode) {
        this.stopLoadingPage();
        GuiErrors.modalError(
          this.i18n.gettext('User not authorized to add product to cart'),
          'Error detail: shopCode null',
          this.i18n.gettext('Chiudi'),
          null,
          null
        );
        return false;
      }

      if (productConfiguration) {
        let [preOrderResult, error] = await this.savePreorder();

        if (preOrderResult) {
          navigateTo(
            this.request,
            this.response,
            'services:EventBook',
            {},
            false,
            mergeQueryParams(this.request.query, {
              preOrderId: this.preOrderId,
              packagingPreOrderId: this.packagingPreOrderId,
              professionalProjectId: this.projectId,
              showNotify: this.showNotify
            })
          );
        } else {
          EventTracker.log(this.customerUser, 'bucket_add_error', {
            product_category: productCategoryInterface(
              this.product.productConfiguration.productType
            ),
            error_code: String(error)
          });
        }
      }
    }
    this.stopLoadingPage();
  }
}
