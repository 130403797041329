import { BaseDebossingElementFormatWidget } from '../../core/components/baseDebossingElementFormatWidget';

export class PackagingDebossingElementFormatWidget extends BaseDebossingElementFormatWidget {
  get name() {
    return 'packagingDebossingElementFormatType';
  }

  get label() {
    return this.i18n.gettext('packaging debossing element');
  }

  get selectedItemProperty() {
    return 'code';
  }

  setConfigurationSelectedItem(selectedItem, reloadView = true) {
    if (reloadView) {
      this.props.delegate.setConfigurationItem(
        this.name,
        selectedItem.id,
        null,
        this.calculatePrice
      );
      this.props.delegate.setConfigurationItem(
        'packagingDebossingElementType',
        selectedItem.code,
        null,
        this.calculatePrice
      );
    } else {
      this.props.delegate.configuration[this.name] = selectedItem.id;
      this.props.delegate.configuration['packagingDebossingElementType'] = selectedItem.code; //eslint-disable-line
      this.props.delegate.render(this.props.delegate.context);
    }
  }
}
